import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectTodaysEventsIds,
  selectUpcomingEventsIds,
  selectPastEventsIds,
  selectEventIdsSortedByStartTime,
} from "../../../features/events/eventsSlice";
import EventItem from "../../../features/events/EventItem";
import LabelView from "../../atoms/LabelView/LabelView";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { muiColors } from "../../../utils/globalStyles";
import { useOutletContext } from "react-router-dom";

export default function AdminEventsView(props) {
  const { isMobile } = useOutletContext();
  const todaysEventsIds = useSelector(selectTodaysEventsIds);
  const upcomingEventsIds = useSelector(selectUpcomingEventsIds);
  const pastEventsIds = useSelector(selectPastEventsIds);

  const [selectedButton, setSelectedButton] = useState("today");
  const isToday = selectedButton === "today";

  const eventsMap = {
    today: { title: "Today's Events", eventIds: todaysEventsIds },
    upcoming: { title: "This Week's Events", eventIds: upcomingEventsIds },
    past: { title: "Past Events", eventIds: pastEventsIds },
  };

  const sortedEventIds = useSelector((state) =>
    selectEventIdsSortedByStartTime(state, eventsMap[selectedButton].eventIds)
  );

  return (
    <div style={styles.container}>
      <div style={{ display: "flex" }}>
        <ButtonView
          text={`Today (${todaysEventsIds.length})`}
          variant="contained"
          sx={{ ml: 2 }}
          color={
            selectedButton === "today" ? muiColors.primary : muiColors.black
          }
          onClick={() => setSelectedButton("today")}
        />
        <ButtonView
          text={`This Week (${upcomingEventsIds.length})`}
          variant="contained"
          sx={{ ml: 2 }}
          color={
            selectedButton === "upcoming" ? muiColors.primary : muiColors.black
          }
          onClick={() => setSelectedButton("upcoming")}
        />
        <ButtonView
          text={`Past (${pastEventsIds.length})`}
          variant="contained"
          sx={{ ml: 2 }}
          color={
            selectedButton === "past" ? muiColors.primary : muiColors.black
          }
          onClick={() => setSelectedButton("past")}
        />
      </div>
      <LabelView text={eventsMap[selectedButton].title} type="subtitle" />
      {sortedEventIds.length === 0 && (
        <LabelView
          text={`Nothing scheduled for ${isToday ? "today" : "this week"}.`}
        />
      )}
      <div style={isMobile ? {} : { display: "flex", flexWrap: "wrap" }}>
        {sortedEventIds.map((id) => (
          <div style={isMobile ? {} : { width: 400, marginRight: 8 }}>
            {/* dont need prefill here */}
            <EventItem key={id} id={id} isMobile showEditButton showDate />
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  // This container style could possibly be moved up one level? Using same for EventFeedView
  container: {
    padding: 16,
  },
};
